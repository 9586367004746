<template>
  <div>
    <div class="haders">
      <div class="content" @click="kefuBoo = true">咨询客服</div>

      <div class="userData">
        <img class="headImg" :src="user_info.avatar" />

        <div class="info">
          <h2>{{ user_info.nickname }}</h2>

          <p class="tips">
            {{
              user_info.is_vip == 1
                ? `有效期到${user_info.vip_ex} `
                : level == 2
                ? `弟子尊享价`
                : is_bind == true && level > 0
                ? "尊享会员已过期"
                : "未认证尊享身份"
            }}
          </p>
          <p @click="timeDialog" class="tips_tro">
            有效期说明 <span style="font-weight: bold;color:#fff">>></span>
          </p>
        </div>

        <template>
          <div class="btn" v-if="is_bind == false" @click="showDialog">
            去认证
          </div>

          <div
            class="btn"
            v-else-if="is_bind == true && level == 0 && user_info.is_vip == 0"
          >
            匠粉
          </div>

          <div
            class="btn"
            v-else-if="is_bind == true && level == 1 && user_info.is_vip == 0"
          >
            资深匠粉
          </div>

          <div
            class="btn"
            v-else-if="
              is_bind == true &&
                (level >= 2 || (level == 1 && user_info.is_vip == 1))
            "
          >
            尊享会员
          </div>

          <div class="btn" v-else>
            尊享会员（过期）
          </div>
        </template>
      </div>
    </div>

    <ul class="card" v-if="level == 1">
      <li :class="son_key == 0 ? 'act' : ''" @click="getList(0, 'click')">
        月卡
      </li>
      <li @click="getList(1, 'click')" :class="son_key == 1 ? 'act' : ''">
        季卡
      </li>
    </ul>
    <ul class="onlyProduct">
      <li class="li" v-for="(item, index) in formList" :key="index">
        <div @click="goHref(item.sku_link)" class="solid"></div>
        <img :src="item.goods_image" class="product_image" />
        <div class="info">
          <h2>{{ item.goods_spec }}</h2>
          <span>原价：¥{{ item.goods_marketprice }}/盒</span>
          <div class="model">
            <b class="price">¥{{ item.goods_price }}/盒</b>

            <template>
              <svg-icon
                v-if="item.num > 0"
                iconClass="sku_subtract_act"
                class="svg"
                @click.stop="skuSubtract(index)"
              ></svg-icon>
              <svg-icon
                v-else
                iconClass="sku_subtract"
                class="svg"
                @click.stop="skuSubtract(index)"
              ></svg-icon>
            </template>

            <b class="sku_num">{{ item.num }}</b>
            <svg-icon
              iconClass="sku_add"
              class="svg"
              @click.stop="skuAdd(index)"
            ></svg-icon>
          </div>
        </div>
      </li>
    </ul>

    <div class="onlyProduct_Fxied">
      <p>总数量不能低于{{ buy_scope.min }}盒</p>
      <div class="info">
        <div class="left">
          <p>共{{ totalNum }}件</p>
          <div class="sum">
            <b>合计：</b>
            <span>¥{{ totalSum }}</span>
          </div>
        </div>
        <div class="btn" @click="submit">
          去结算
        </div>
      </div>
    </div>

    <van-overlay :show="boo" @click="boo = false">
      <div class="wrapper" @click.stop>
        <div class="rule">
          <div class="solid">
            <span class="title">请绑定手机号码</span>
            <div class="input_text">
              <van-field
                v-model="phone"
                placeholder="请输入手机号"
                clearable
                :maxlength="11"
              />
            </div>

            <ul class="btn">
              <li @click="boo = false">
                取消
              </li>
              <li
                @click="setPhone"
                :style="{ opacity: phone.length == 11 ? '1' : '0.3' }"
              >
                确认
              </li>
            </ul>

            <svg-icon
              icon-class="dialog_error"
              class="tips_error"
              @click="boo = false"
            ></svg-icon>
          </div>
        </div>
      </div>
    </van-overlay>

    <oChat :boo.sync="kefuBoo"></oChat>
  </div>
</template>

<script>
import { Overlay, Field, Toast, Dialog } from "vant";
import { post, get } from "@get/http.js";
import oChat from "@/view/me/view/chat/popup.vue";
export default {
  components: {
    vanOverlay: Overlay,
    vanField: Field,
    oChat,
  },
  data() {
    return {
      totalSum: 0,
      totalNum: 0,
      formList: [],
      kefuBoo: false,
      level: 0,
      is_bind: false,
      boo: false,
      phone: "",
      user_info: {},
      son_key: 0,
      notice: "",
      buy_scope: {
        max: 999,
        min: 10,
      },
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    timeDialog() {
      Dialog.alert({
        title: "【会员期限说明】",
        messageAlign: "left",
        message: this.notice,
      }).then(() => {
        // on close
      });
    },
    async getList(son_key = 0, mode = "default") {
      if (
        this.totalNum > this.buy_scope.min - 1 &&
        son_key == 0 &&
        mode == "click"
      ) {
        Toast(
          `月卡最高只能买${this.buy_scope.min -
            1}盒哦，如需要月卡请将数量减至${this.buy_scope.min - 1}盒`
        );
        return false;
      }
      this.son_key = son_key;
      let { list, level, is_bind, user_info, buy_scope, notice } = await get(
        `/v3.CustomerIndependent/getList?son_key=${son_key}`,
        {
          headers: {
            "X-DS-KEY": this.$store.state.user.token,
          },
        }
      );

      if (this.formList[0]?.num != undefined) {
        for (let index = 0; index < this.formList.length; index++) {
          list[index].num = this.formList[index].num;
        }
      } else {
        for (let index = 0; index < list.length; index++) {
          list[index].num = 0;
        }
      }

      this.formList = list;
      this.sumPrice();
      this.is_bind = is_bind;
      this.user_info = user_info;
      this.buy_scope = buy_scope;
      this.notice = notice;
      // this.sumPrice();
      if (is_bind == false) {
        this.boo = true;
      }
      this.level = level;

      return {
        list,
        level,
        is_bind,
        user_info,
        buy_scope,
        notice,
      };
    },
    showDialog() {
      if (this.is_bind == false) {
        this.boo = true;
      }
    },

    submit() {
      let f = this.formList,
        sum = 0;
      for (let index = 0; index < f.length; index++) {
        sum += f[index].num;
      }
      if (sum == 0) {
        Toast("你需要买点什么哦~");
        return false;
      }

      if (this.is_bind == false) {
        Dialog.confirm({
          title: "提示",
          message: "您需要绑定手机号码再能继续操作哦~",
        })
          .then(() => {
            // on confirm
            this.boo = true;
          })
          .catch(() => {
            // on cancel
          });
        return false;
      }
      if (sum < this.buy_scope.min && this.is_bind == true) {
        Toast(`购买总数量不能低于${this.buy_scope.min}盒`);
      } else if (sum > this.buy_scope.max && this.is_bind == true) {
        Toast(
          `数量已经超过${this.buy_scope.max}盒,建议您切换到季卡或者联系客服`
        );
      } else {
        let cart_id = "";
        for (let index = 0; index < this.formList.length; index++) {
          if (this.formList[index].num != 0) {
            cart_id += `${this.formList[index].goods_id}|${
              this.formList[index].num
            }${index != this.formList.length - 1 ? "," : ""}`;
          }
        }
        let params = {
          son_key: this.son_key,
          cart_id,
        };

        this.$router.replace({ name: "Buy_Only", query: params });

        // Toast("购买成功");
      }
    },
    goHref(href) {
      window.location.href = href;
    },
    async setPhone() {
      if (this.phone.length != 11) {
        Toast("请输入11位正确的手机号码");
        return false;
      }
      Toast.loading({
        message: "获取中",
        forbidClick: true,
        duration: 0,
      });
      let data = await post("/v3.CustomerIndependent/saveCommunityPhone", {
        data: {
          phone: this.phone,
        },
      });

      this.boo = false;
      let { level } = await this.getList();

      Toast(data.message);
      if (level != 0) {
        window.location.reload();
      } else {
        Toast.clear();
      }

      // if(this.level == 0){

      // }
    },

    sumPrice() {
      let sum = 0;
      let num = 0;
      for (let index = 0; index < this.formList.length; index++) {
        sum +=
          parseFloat(this.formList[index].goods_price) *
          this.formList[index].num;
        num += this.formList[index].num;
      }
      this.totalSum = parseFloat(sum).toFixed(2);
      this.totalNum = num;
    },
    skuSubtract(index) {
      if (this.formList[index].num == 0) {
        Toast("不能再少了~");
        return false;
      } else {
        // Toast({
        //   message: "已超过最高优惠，已为你切换最优方案",
        //   duration: 2000,
        // });
        if (this.son_key == 1 && this.totalNum - 1 < this.buy_scope.min) {
          this.formList[index].num--;
          this.getList(0);
        } else {
          this.formList[index].num--;
          this.sumPrice();
        }
      }
    },
    skuAdd(index) {
      if (this.totalNum + 1 > this.buy_scope.max) {
        if (this.son_key == 0) {
          // Toast({
          //   message: "已达到最高优惠，已为你切换最优方案",
          //   duration: 2000,
          // });
          this.formList[index].num++;
          this.getList(1);
        } else {
          Toast("最高只可以买" + this.buy_scope.max + "哦~");
        }
      } else {
        this.formList[index].num++;
        this.sumPrice();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.haders {
  width: rem(750);
  height: rem(282);
  background: url("~@image/goods/goodsTailor/bg.png") no-repeat;
  background-size: rem(750) rem(282);
  position: relative;
  margin: 0 auto;
  .content {
    border: rem(2) solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    border-radius: rem(22) 0px 0px rem(22);
    color: rgba(255, 255, 255, 0.8);
    font-size: rem(24);
    width: rem(136);
    height: rem(44);
    text-align: center;
    line-height: rem(44);
    position: absolute;
    top: rem(24);
    right: rem(-2);
    font-size: rem(24);
  }
  .userData {
    display: flex;
    padding: rem(132) rem(50);
    box-sizing: border-box;
    .headImg {
      width: rem(90);
      height: rem(90);
      border-radius: 50%;
      margin-right: rem(16);
    }
    .info {
      flex: 1;
      h2 {
        color: #ffffff;
        font-weight: bold;
        font-size: rem(38);
      }
      p {
        color: rgba(255, 255, 255, 0.6);
        font-size: rem(24);
      }
    }
    .btn {
      background: #ffffff;
      border-radius: rem(22);
      width: rem(120);
      height: rem(44);
      color: #ff4848;
      line-height: rem(44);
      text-align: center;
      font-size: rem(24);
    }
  }
}

.onlyProduct {
  width: rem(690);
  margin: rem(24) auto 0;
  .li {
    margin-top: rem(32);
    display: flex;
    box-sizing: border-box;
    position: relative;
    .solid {
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      height: 100%;
    }
    .product_image {
      width: rem(184);
      height: rem(184);
      border-radius: rem(16);
      margin-right: rem(16);
    }
    .info {
      flex: 1;
      > h2 {
        font-size: rem(28);
        color: #222222;
        height: rem(92);
        @include ell;
        margin-top: rem(10);
      }
      > span {
        font-size: rem(22);
        color: #989898;
      }
      .model {
        display: flex;
        align-items: center;
        font-size: rem(30);
        .price {
          color: #ff4848;
          font-weight: bold;
          flex: 1;
        }
        .sku_num {
          width: rem(30);
          display: inline-block;
          text-align: center;
          color: #222222;
          padding: 0 rem(10) 0 rem(6);
        }
        .svg {
          width: rem(38);
          height: rem(38);
        }
      }
    }
  }
}

.onlyProduct_Fxied {
  position: fixed;
  left: 0px;
  width: 100%;
  bottom: 0px;

  box-sizing: border-box;
  > p {
    background: #fff6e7;
    color: #fb7e1c;
    text-align: center;
    width: 100%;
    font-size: rem(22);
    line-height: rem(50);
    height: rem(50);
  }
  .info {
    display: flex;
    padding: rem(30) rem(30);
    box-sizing: border-box;
    width: 100%;
    height: rem(100);
    align-items: center;
    .left {
      flex: 1;
      > p {
        color: #222222;
        font-size: rem(24);
      }
      .sum {
        b {
          color: #222222;
          font-weight: bold;
          font-size: rem(28);
        }
        span {
          color: #ff4848;
          font-weight: bold;
          font-size: rem(34);
        }
      }
    }
    .btn {
      background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
      border-radius: rem(40);
      width: rem(278);
      height: rem(80);
      font-weight: bold;
      font-size: rem(28);
      line-height: rem(80);
      text-align: center;

      color: #ffffff;
    }
    background: #ffffff;
  }
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: rem(2) solid #ff4848;
  border-radius: rem(8);
  width: rem(156 * 2);
  margin: rem(56) auto 0;
  .act {
    background: #ff4848 !important;
    color: #ffffff !important;
  }
  li {
    box-sizing: border-box;
    width: rem(156);
    height: rem(64);
    color: #ff4848;
    font-weight: bold;
    font-size: rem(28);
    line-height: rem(64);
    text-align: center;
    &:first-of-type {
    }
    &:last-of-type {
    }
  }
}

.rule {
  width: rem(558);
  height: rem(385);
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  > .solid {
    .title {
      color: #222222;
      font-weight: bold;
      font-size: rem(34);
      text-align: center;
      display: block;
      margin-top: rem(40);
    }
    .input_text {
      margin: rem(20) rem(48) rem(56);
      border-bottom: 1px solid #c4c4c4;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .act {
        li {
          &:last-of-type {
            opacity: 1;
          }
        }
      }
      li {
        width: rem(216);
        height: rem(84);
        box-sizing: border-box;
        border-radius: rem(42);
        box-sizing: border-box;
        text-align: center;
        line-height: rem(84);
        &:first-of-type {
          color: #666666;
          border: rem(2) solid #989898;
          margin-right: rem(30);
        }
        &:last-of-type {
          background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
          color: #ffffff;
          opacity: 0.3;
        }
      }
      .l_act {
        opacity: 1;
      }
    }
  }
}

.solid2 {
  .title {
    font-size: rem(30);
  }
  .tips {
    box-sizing: border-box;
    padding: 0 rem(42);
    margin-top: rem(24);
  }
  .tips_tro {
    font-size: rem(28);
  }
  .btn {
    border: rem(2) solid #ff4848;
    border-radius: rem(42);
    width: rem(380);
    height: rem(84);
    margin: rem(56) auto 0;

    li {
      color: #ff4848 !important;
      background: none !important;
      border: none !important;
      opacity: 1 !important;
      font-size: rem(30);
    }
  }
}

.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
